<template>
  <v-card :loading="dialog.loading" max-width="900" elevation="0" class="mx-auto">
    <!-- logo -->
    <v-card-title class="d-flex py-7">
      <h2 class="text-2xl font-weight-semibold">
        Concours
      </h2>
      <v-spacer>
      </v-spacer>
      <!--          <v-btn
                  color="primary"
                  small
                >
                  Marquer comme toutes lues
                </v-btn>-->
    </v-card-title>

    <v-card-text>
      <template v-for="(item, index) in ll.listEntity">
        <v-card
          :key="'c-'+index" :to="'/contest/'+item.id"
          class="mb-3 mx-auto" elevation="1"
        >
          <v-img v-if="item.post.postimages"
                 :src="item.post.postimages[0].uploaddir+''+item.post.postimages[0].image"
                 height="100"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0 "
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <v-card-title class="pa-1">

            <v-list-item-avatar color="grey darken-3">
              <v-img
                v-if="item.post.user.avatar"
                contain
                class="elevation-6"
                alt=""
                :src="require('@/assets/images/avatars/'+item.post.user.avatar)"
              ></v-img>
              <v-img
                v-else
                contain
                class="elevation-6"
                alt=""
                :src="require('@/assets/images/avatars/logo.png')"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="">
                <router-link :to="'/contest/'+item.id"><b>{{ item.cmstext.title }}</b>
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle>{{ item.created_at }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-spacer></v-spacer>

            <v-btn small elevation="0" class="text--primary">
              <v-icon>{{ icons.mdiHeartOutline }}</v-icon>
              {{ item.post.nbkola }}
            </v-btn>
            <v-btn small elevation="0" class="text--primary">
              <v-icon>{{ icons.mdiCommentOutline }}</v-icon>
              {{ item.post.nbcomment }}
            </v-btn>
          </v-card-title>

        </v-card>
      </template>
    </v-card-text>
    <!-- login form -->
  </v-card>

</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHeartOutline,
  mdiCommentOutline,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {useRouter} from '@/utils'

export default {
  components: {},
  setup() {
    const user = Drequest.getUser()
    const env = Drequest.__env
    const subscription = ref({})
    const ll = ref({})
    const dialog = ref({
      newrefill: false,
      snack: false,
      confirm: false,
      loading: false,
    })

    const {router} = useRouter()
    const route = router.currentRoute
    if (route.params.option) dialog.value.newrefill = true

    dialog.value.loading =true
    const init = () => {
      Drequest.api(
        user.id ? 'lazyloading.contest?dfilters=on&dsort=id desc&user_id=' + user.id
          : 'lazyloading.contest?dfilters=on&dsort=id desc'
      )
        .get(response => {
          dialog.value.loading = false
          console.log(response)
          ll.value = response
        })
    }
    init()

    const refilled = () => {
      dialog.value.newrefill = false
      dialog.value.snack = true
      init()
    }
    const redirect = (item) => {
      if (item.redirect)
        return item.redirect
      if (item.entity === 'post')
        return '/activity/' + item.entityid

      return '/social'
    }

    return {
      init,
      redirect,
      refilled,
      ll,
      subscription,
      user,
      dialog,
      env,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
        mdiHeartOutline,
        mdiCommentOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
